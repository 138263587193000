$background-color: 		#fcfcfc;

$black:					#000;
$white:					#fff;

$grey-color-darker: 	#111;
$grey-color-dark: 		#333;
$grey-color-normal: 	#666;
$grey-color-light: 		#aaa;
$grey-color-lighter: 	#ddd;
$grey-color-lightest: 	#eee;

$primary-color:			#dc0e0e;

$facebook-brand-color:	#3b5998;
$twitter-brand-color:	#55acee;

$default-width:			1100px;
$on-tablet:				768px;
$on-smaller-tablets:    640px;
$on-phone:				480px;

$burger-cell-height:    2px;